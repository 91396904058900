import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/articulo8.png";
import noticias18 from "../../../../static/img/blog/noticias-18-.jpg";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='Aunque los presupuestos de RI disminuyen durante la pandemia, los equipos se mantienen sólidos'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias18}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                10 Marzo. 2021
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : Miguel Bermejo
                </span>
                                <span>
                    Análisis del mercado
                </span>
                            </div>
                        </div>

                        <h2>Aunque los presupuestos de RI disminuyen durante la pandemia, los equipos se mantienen sólidos</h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                <p>Como consecuencia de la pandemia de COVID-19, las compañías se han enfrentado a un entorno muy complejo,
                en el que se han presentado recortes tanto en la plantilla laboral como en los presupuestos destinados
                para sus diferentes áreas. En este respecto y en línea con la investigación realizada por <em>IR Magazine</em>,
                durante el último año, los presupuestos de RI han disminuido notablemente debido a la reducción de costos
                desplegada por las empresas. </p>

                <p>Si bien, la disminución a nivel general asciende a 16%, el efecto varía según el nivel de capitalización
                de las compañías, siendo las de mediana capitalización las más afectadas al registrar un decremento de
                casi una cuarta parte desde 2019. Lo anterior, podría atribuirse a que: </p>


                    <p class="ml-list">I.	Las empresas de alta capitalización, en su mayoría, son las que cuentan con mayor liquidez
                    en el mercado de valores, tal que sus acciones son las primeras en reflejar el sentimiento actual
                    del mercado. Por esta razón, una de sus prioridades es siempre mantener una estrecha comunicación
                    con sus inversionistas para prevenir, en la medida de lo posible, una caída significativa del precio
                    de la acción; y,</p>

                    <p class="ml-list">II.	Las emisoras de mediana y baja capitalización suelen estar en proceso de adopción de las
                    mejores prácticas de RI y de impulsar una mayor liquidez de su acción; por lo que, en coyunturas
                    económicas de alto incertidumbre (como la pandemia de    COVID-19), donde privilegiar la posición
                    de efectivo es fundamental, restringir los recursos del departamento de RI es una de las primeras
                    opciones, dado que la baja operatividad de la acción propicia que su precio refleje con cierto
                    desfase de tiempo las tendencias derivadas del contexto actual.
                    </p>

                <p>No obstante, aunque el presupuesto ha disminuido (entre otras cosas, por la nula realización de
                  <em>roadshows</em> y visitas a sitio), las compañías públicas tienen muy presente la importancia
                  de continuar informando de manera oportuna y efectiva a sus inversionistas (sobre todo en un
                    entorno de crisis), puesto que las plantillas laborales de los equipos del área de RI no sólo
                    no disminuyeron durante el último año, sino que registraron un ligero aumento al pasar de 2.6
                    personas a 2.8, según la encuesta de IR Magazine. </p>

                <p>De esta manera, se puede validar la relevancia que tiene para las emisoras de bolsa el seguir
                alimentando la confianza de sus inversionistas en todo momento y ante cualquier circunstancia,
                pues este esfuerzo, sin duda, rendirá buenos frutos en el mediano y largo plazo. </p>

                <p>Referencias Bibliográficas
                  <ul>
                    <li>Human, T. (2021). IR budgets drop by 16 percent as companies rein in costs during pandemic, finds global survey. Enero 26, 2021. <a href="https://www.irmagazine.com/case-studies/ir-budgets-drop-16-percent-companies-rein-costs-during-pandemic-finds-global-survey" target="_blank">IR Magazine</a>.</li>
                  </ul>
                </p>
                </div>
              </div>
        </Single>
    )
}

export default Detail
